import React, { useState, useEffect } from 'react';
import 'animate.css';
import SiteLinks from './SiteLinks/SiteLinks.js';
import Logo from './images/ecash-logo-horizontal.png';
import Ecash from './images/ecash-logo-background.png';
import Pizza from './images/pizza-slice.png';
import Background from './images/video-img.jpg';
import Hand from './images/hand.png';
import { ResponsiveBar } from '@nivo/bar';
import Video from './images/video-background.mp4';
import Confetti from 'react-confetti';

// Price of a large pepperoni pizza from Dominos
const pizza2021 = 10.23;
const pizza2022 = 11.5;
const pizza2023 = 12.99;
const pizza2024 = 14.99;

const App = () => {
  const today = new Date();
  const month = today.getMonth();
  const date = today.getDate();
  const [itspizzaday, setItsPizzaDay] = useState(
    month === 4 && date === 22 ? true : false
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(
      'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ecash',
      {}
    )
      .then((res) => res.json())
      .then((response) => {
        const pizzainecash = (pizza2024 / response[0].current_price).toFixed();
        setData([
          {
            date: 'May 22, 2021',
            ecash_price: 0.00002635,
            pizza: (pizza2021 / 0.00002635).toFixed(),
          },
          {
            date: 'May 22, 2022',
            ecash_price: 0.0000463,
            pizza: (pizza2022 / 0.0000463).toFixed(),
          },
          {
            date: 'May 22, 2023',
            ecash_price: 0.00002561,
            pizza: (pizza2023 / 0.00002561).toFixed(),
          },
          {
            date: 'May 22, 2024',
            ecash_price: 0.00005152,
            pizza: (pizza2024 / 0.00005152).toFixed(),
          },
          {
            date: 'Today',
            ecash_price: response[0].current_price,
            pizza: pizzainecash,
          },
        ]);
      })
      .catch((error) => console.log(error));
  }, []);

  const options = {
    background: 'rgba(255,255,255,0.06)',
    textColor: '#fff',
    fontSize: 12,
    axis: {
      domain: {
        line: {
          stroke: '#777777',
          strokeWidth: 1,
        },
      },
      legend: {
        text: {
          fontSize: 12,
          fill: '#fff',
        },
      },
      ticks: {
        line: {
          stroke: '#fff',
          strokeWidth: 1,
        },
        text: {
          fontSize: 11,
          fill: '#fff',
        },
      },
    },
    grid: {
      line: {
        stroke: 'rgba(255,255,255,0.4)',
        strokeWidth: 1,
      },
    },
    legends: {
      title: {
        text: {
          fontSize: 11,
          fill: '#333333',
        },
      },
      text: {
        fontSize: 11,
        fill: '#fff',
      },
      ticks: {
        line: {},
        text: {
          fontSize: 10,
          fill: '#fff',
        },
      },
    },
    tooltip: {
      container: {
        background: '#ffffff',
        color: '#333333',
        fontSize: 12,
      },
    },
  };

  return (
    <div>
      <div className="block">
        <div className="container">
          <div className="header">
            <a href="https://e.cash" target="_blank" rel="noreferrer">
              <img
                src={Logo}
                alt="ecash logo"
                className="animate__animated animate__fadeInUp"
                style={{ animationDelay: '.4s' }}
              />
            </a>
          </div>
        </div>
      </div>

      {/***************** HERO SECTION *****************/}

      <div className="block">
        <div
          className="landingbackground-image"
          style={{ backgroundImage: 'url(' + Background + ')' }}
        />
        <div className="overlay" />
        <div className="video-ctn">
          <video
            playsInline
            autoPlay
            muted
            loop
            poster={Background}
            id="ecash_background"
          >
            <source src={Video} type="video/mp4" />
          </video>
        </div>
        <div className="container">
          <div className="hero">
            {itspizzaday && (
              <>
                <Confetti
                  width={window.innerWidth}
                  height={document.body.scrollHeight}
                  numberOfPieces={100}
                  // recycle={false}
                />
                <div className="animate__animated animate__fadeInUp toptext-ctn">
                  <h1 className="glitch" data-text="Happy eCash Pizza Day!">
                    Happy eCash Pizza Day!
                  </h1>
                  <img src={Hand} alt="ecash pizza" className="hand" />
                </div>
                <div
                  className="animate__animated animate__fadeInUp pizza-img"
                  style={{
                    animationDelay: '.2s',
                    position: 'relative',
                    textAlign: 'center',
                  }}
                >
                  <img
                    src={Pizza}
                    alt="ecash pizza"
                    onClick={() => setItsPizzaDay(!itspizzaday)}
                  />
                </div>
              </>
            )}
            {!itspizzaday && (
              <>
                <div className="animate__animated animate__fadeInUp toptext-ctn">
                  <h1 className="glitch" data-text="eCash Pizza Day">
                    eCash Pizza Day
                  </h1>
                  <img src={Hand} alt="ecash pizza" className="hand" />
                </div>
                <div
                  className="animate__animated animate__fadeInUp pizza-img"
                  style={{
                    animationDelay: '.2s',
                    position: 'relative',
                    textAlign: 'center',
                  }}
                >
                  <img
                    src={Pizza}
                    alt="ecash pizza"
                    // onClick={() => setItsPizzaDay(!itspizzaday)}
                  />
                </div>
                <h2>
                  May 22<sup>nd</sup>
                </h2>
              </>
            )}
          </div>
        </div>
      </div>

      {/************************ TEXT SECTION *********************/}

      <div className="block">
        <div className="gradient" />
        <div
          className="container p-background"
          style={{
            backgroundImage: `url(${Ecash})`,
          }}
        >
          <p
            className="text-p animate__animated animate__fadeInUp"
            style={{ animationDelay: '.5s' }}
          >
            May 22nd is known as Bitcoin Pizza Day, and marks the anniversary
            where in 2010 a Florida man, Laszlo Hanyecz, paid for two pizzas
            using 10,000 Bitcoin. It is celebrated as the first commercial
            transaction using Bitcoin.
            <br />
            <br />A lot has changed since 2010, and while Bitcoin (BTC) has
            shifted away from the idea of electronic cash for everyday
            transactions like pizza, eCash continues to build on the dream of a
            worldwide, scalable, decentralized, low cost means of payment.
            <br />
            <br />
            So this Pizza Day let’s celebrate the dream of economic freedom and
            electronic cash. Let’s celebrate eCash!
          </p>
        </div>
      </div>

      {/************************ Chart SECTION *********************/}

      <div className="block">
        <div className="container">
          <div
            className="chart-ctn animate__animated animate__fadeInUp"
            style={{ animationDelay: '.5s' }}
          >
            <h3>eCash vs Pizza</h3>
            <ResponsiveBar
              data={data}
              keys={['pizza']}
              indexBy="date"
              margin={{ top: 50, right: 0, bottom: 70, left: 80 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={'#0074c2'}
              theme={options}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '1 Pepperoni Pizza',
                legendPosition: 'middle',
                legendOffset: 50,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'XEC',
                legendPosition: 'middle',
                legendOffset: -60,
                format: (v) => `${v / 1000}K`,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={'#fff'}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="eCash vs Pizza"
              barAriaLabel={function (e) {
                return 'Pizza price in eCash';
              }}
              colorBy="indexValue"
              tooltip={({ id, value, color }) => (
                <div
                  style={{
                    padding: 6,
                    color: '#333',
                    background: '#fff',
                  }}
                >
                  1 Pepperoni Pizza = {value.toLocaleString()} XEC
                </div>
              )}
            />
          </div>
          <p className="footnote">
            Based on the cost of a large pepperoni pizza from Domino's Pizza for
            the given year in USD.
          </p>
        </div>
      </div>

      {/************************ LEARN MORE SECTION *********************/}

      <div
        className="block animate__animated animate__fadeInUp"
        style={{ animationDelay: '1s' }}
      >
        <div className="container">
          <div className="future-ctn" style={{ marginTop: '50px' }}>
            <h3>Learn More about eCash</h3>
            <p>There is so much more to eCash. Visit e.cash to learn more.</p>

            <a
              href="https://e.cash"
              target="_blank"
              rel="noreferrer"
              className="learn-btn"
            >
              <div>Learn More</div>
            </a>
          </div>
        </div>
      </div>

      {/************************ SITE LINKS SECTION *********************/}

      <SiteLinks />
    </div>
  );
};

export default App;
